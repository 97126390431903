import { API } from '@/service/index';
import { initializeApp } from 'firebase/app';
import { signInWithPhoneNumber, RecaptchaVerifier, getAuth  } from 'firebase/auth';


// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDa4VXIMcb-a1iZJsHcIpJI7nVcg3TJRCo",
//   authDomain: "textmessage-e3a47.firebaseapp.com",
//   databaseURL: "https://textmessage-e3a47.firebaseio.com",
//   projectId: "textmessage-e3a47",
//   storageBucket: "textmessage-e3a47.appspot.com",
//   messagingSenderId: "325828818760",
//   appId: "1:325828818760:web:03c7bf3d333f7966ac257d",
//   measurementId: "G-WR73T6TP9V"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const FIREBASE = {
  init(){
    initializeApp({
      // apiKey: "AIzaSyDa4VXIMcb-a1iZJsHcIpJI7nVcg3TJRCo",
      // authDomain: "textmessage-e3a47.firebaseapp.com",
      // databaseURL: "https://textmessage-e3a47.firebaseio.com",
      // projectId: "textmessage-e3a47",
      // storageBucket: "textmessage-e3a47.appspot.com",
      // messagingSenderId: "325828818760",
      // appId: "1:325828818760:web:03c7bf3d333f7966ac257d",
      // measurementId: "G-WR73T6TP9V"
      
      apiKey: "AIzaSyA9C28mXkWzvAIKdtETSTt13WJr6wRSA_U",
      authDomain: "voipbusiness-1dc67.firebaseapp.com",
      databaseURL: "https://voipbusiness-1dc67-default-rtdb.firebaseio.com",
      projectId: "voipbusiness-1dc67",
      storageBucket: "voipbusiness-1dc67.appspot.com",
      messagingSenderId: "157270702201",
      appId: "1:157270702201:web:206cf3f1e74c0b5272fca8",
      measurementId: "G-7ZFF37N69R"
    });
    return this
  },
  recaptchaRender(id,onSuccess,onExpired){
    const auth = getAuth()
    const recaptchaVerifier = new RecaptchaVerifier(id,{
      'size': 'normal',
      'callback': (response) => {
        onSuccess?.(response)
      },
      'expired-callback': () => {
        onExpired?.()
      }
    },auth);
    recaptchaVerifier.render();
    return recaptchaVerifier
  },
  async sendOTP(number,recaptchaVerifier){
    return new Promise((resolve,reject)=>{
      const auth = getAuth()
      setTimeout(()=>{
        reject(new Error('Resolve Recaptcha'))
      },5*1000)
      signInWithPhoneNumber(auth, number, recaptchaVerifier)
      .then((result)=>{
        resolve(result)
      })
      .catch((ex)=>{
        reject(ex)
      })
    })
  },
  verify(code,coderesult){
    return coderesult?.confirm?.(code)
  },
  exceptionErrorType(ex){
    console.log(ex)
    if(ex?.message?.startsWith?.('Firebase')){
      console.log(ex)
      return ex?.message?.replace?.('Firebase: Error (','')?.replace?.(').','') ?? ''
    }
    return ''
  },
  exceptionErrorMessage(ex){
    let message = ''
    const type = this.exceptionErrorType(ex)
    if(type){
      switch (type) {
        case 'auth/invalid-phone-number':
          message = 'Invalid Number'
          break;
        case 'auth/invalid-verification-code':
          message = 'Invalid Pincode'
          break;
        case 'auth/too-many-requests':
          message = 'Too many attempts try again after a minute'
          break;
        default:
          message = API.errorMessages.default
          break;
      }
    }
    return message
  },
}