<template>
  <div>
    <div class="signUpPage-container">
      <SipupSlider />
      <div class="signUpPage-rightSide">
        <div class="signUpPage-rightSide-inner">
          <b-form @submit.prevent="assign()">
            <b-alert :show="!!api.assign.error_message" variant="danger">{{ api.assign.error_message }}</b-alert>
            <div class="mainHeading mb-40px mt-16px">Choose your telephone number</div>
            <div class="twoTabsContainer">
              <div 
                v-for="tab in tabs" 
                :key="tab.value" 
                class="twoTabs-item"
                :class="{ 
                  'selected active-tab-button': selected.tab === tab.value, 
                }" 
                @click="selected.tab = tab.value;"
              >
                <img :src="tab.flag" alt="icon"/>
                {{ tab.label }}
              </div>
            </div>
            <div class="tabContent mt-40px">
              <template v-if="selected.tab==='usa'">
                <div class="mainInput-container">
                  <label>Select region</label>
                  <div class="position-relative mainInput">
                    <div class="mx-auto position-absolute horizontal-centring">
                      <b-spinner small variant="primary" class="mx-auto" v-if="api.regions.send" />
                    </div>
                    <model-select 
                      name="region-select" 
                      :disabled="api.regions.send" 
                      :options="regionsOptions" 
                      v-model="selected.region" 
                      placeholder="Select region" 
                    />
                  </div>
                </div>
                <div v-if="selected.region" class="mainInput-container mt-16px">
                  <label>Select location</label>
                  <div class="position-relative mainInput" >
                    <div class="mx-auto position-absolute horizontal-centring">
                      <b-spinner small variant="primary" v-if="api.groups.send" />
                    </div>
                    <model-select 
                      name="location-select" 
                      :disabled="api.groups.send" 
                      :options="groupsOptions" 
                      v-model="selected.group" 
                      placeholder="Select location" 
                    />
                  </div>
                </div>
              </template>
              <template v-if="selected.tab==='uk'">
                <div class="mainInput-container">
                  <label>Select city</label>
                  <div class="position-relative mainInput">
                    <div class="mx-auto position-absolute horizontal-centring">
                      <b-spinner small variant="primary" v-if="api.cities.send" /> 
                    </div>
                    <model-select :disabled="api.cities.send" :options="citiesOptions" v-model="selected.city" placeholder="Select city" />
                  </div>
                </div>
              </template>
              <div class="NumberSelectContainer">
                <div class="w-100 NumberSelectContainer-inner-container" v-if="api.numbers.send">
                  <div class="NumberSelectContainer-inner">
                    <div>
                      <div class="latestShimmerDesign" v-for="n in 8" :key="n.id" style="width: calc(50% - 16px);height: 50px;margin: 8px;"></div>
                    </div>
                  </div>
                </div>
                <div class="emptyScreenContainer" v-if="response.numbers.length==0 && api.numbers.error_message">
                  <img class="mt-12px w-50 h-auto" :src="require('@/assets/images/emptyScreenImages/nonumbersavailable.png')"/>
                  <div class="emptyScreenContainer-heading mt-32px">
                    No numbers available here
                  </div>
                  <div class="emptyScreenContainer-text w-100">
                    We're sorry, but there are currently no phone numbers available in 
                    this country or city. We're working on expanding our coverage, so 
                    please check back later or explore other locations.
                  </div>
                </div>
                <div v-if="response.numbers.length!=0" class="w-100 NumberSelectContainer-inner-container">
                  <b-form-group id="phone-numbers-radio" class="NumberSelectContainer-inner">
                    <b-form-radio 
                      :disabled="api.assign.send" 
                      v-model="forms.assign.number" 
                      :class="`${forms.assign.number==number.number?'active':''}`" 
                      v-for="number in filterNumbers" 
                      :key="number.number" 
                      :name="number.number" 
                      :value="number.number"
                    >
                      <div class="ch_plaeholder"></div>
                      <span>{{ number.number | number_formater(selectedCountryCode)}}</span>
                      <small>Free</small>
                    </b-form-radio>
                  </b-form-group>
                  <p v-if="(forms.assign.submitted && $v.forms.assign.number.$invalid)" class="mb-1 text-small text-danger animated bounceIntop">
                    <span v-if="!$v.forms.assign.number.required">* Number is required</span>
                  </p>
                  <!-- <div class="signup-pagination-v2">
                    <div @click="pagination.numbers.current_page!=1?pagination.numbers.current_page=pagination.numbers.current_page-1:''" class="sides">
                      <b-icon icon="chevron-left"/>
                    </div>
                    <div 
                      v-for="page in totalNumbersPages" 
                      :key="page" @click="pagination.numbers.current_page=page" 
                      :class="`p-circle ${pagination.numbers.current_page==page?'selected':''}`" 
                    />
                    <div 
                      @click="pagination.numbers.current_page!=totalNumbersPages?pagination.numbers.current_page=pagination.numbers.current_page+1:''" 
                      class="sides"
                    >
                      <b-icon icon="chevron-right"/>
                    </div>
                  </div> -->
                </div>

              </div>
              <div v-if="selected.tab==='uk' || (selected.tab==='usa' && selected.region && selected.group)" class="w-100 mt-20px">
                <b-button id="next-button" :disabled="!forms.assign.number" class="fullBlackBTN" type="submit" >
                  <app-spinner v-if="api.assign.send" /> 
                  <template v-else>Next</template>
                </b-button>
              </div>
            </div>
          </b-form>
        </div>
        <div class="bottomSection">
          Already have a account ? 
          <a @click="$emit('login-click')" class="mx-2 font-weight-bold font-size-14 color-primary">Log in</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, } from "vuelidate/lib/validators";
import { API } from '../service'
import _ from 'lodash'
import { ModelSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'
import parsePhoneNumber from 'libphonenumber-js'
import SipupSlider from './SipupSlider.vue'
const tabs = [
  {
    flag: require("@/assets/images/uk.png"),
    label: "UK Numbers",
    value: 'uk',
  },
  {
    flag: require("@/assets/images/usa.png"),
    label: "USA Numbers",
    value: 'usa',
  },
]
function getValidPhoneNumberObject(number,country){
  if (!number || !['string','number'].includes(typeof number)) return null;
  const phoneNumber = parsePhoneNumber(`${number}`, country)
  if (phoneNumber && phoneNumber.isValid()) return phoneNumber
  // const phoneNumberWithPlus = parsePhoneNumber(`+${number}`)
  // if (phoneNumberWithPlus && phoneNumberWithPlus.isValid()) return phoneNumberWithPlus
  else return null;
}
export default {
  name: 'SelectNumberNew',
  components: {
    ModelSelect,
    SipupSlider
  },
  data(){
    return {
      forms: {
        assign: this.$app.form({
          data: {
            number: '',
          }
        }),
      },
      response: {
        numbers: [],
        regions: [],
        groups: [],
        cities: [],
      },
      api: {
        numbers: this.$app.api({
          error_message: true,
          source: true,
        }),
        regions: this.$app.api({
          error_message: true,
        }),
        groups: this.$app.api({
          error_message: true,
        }),
        assign: this.$app.api({
          error_message: true,
        }),
        cities: this.$app.api({
          error_message: true,
        }),
      },
      selected: {
        tab: tabs[0].value,
        city: '',
        region: '',
        group: '',
      },
      pagination: {
        numbers: {
          current_page: 1,
          per_page: 8,
          totalPages: 0
        },
      },
    }
  },
  computed: {
    tabs(){ return tabs },
    filterNumbers(){
      const numbers = this.response.numbers
      const end = this.pagination.numbers.current_page * this.pagination.numbers.per_page - 1
      const start = end - this.pagination.numbers.per_page + 1
      return numbers.slice(start,end+1);
    },
    totalNumbersPages(){ return Math.ceil((this.response.numbers.length > 80 ? 80 : this.response.numbers.length)/this.pagination.numbers.per_page) },
    regionsOptions(){
      return this.response.regions.map(i=>({
        text: i.label,
        value: i.code,
      }))
    },
    groupsOptions(){
      return this.response.groups.map(i=>({
        text: i.label,
        value: i.group_id,
      }))
    },
    citiesOptions(){
      return this.response.cities.map(i=>({
        text: i.name,
        value: i.citycode,
      }))
    },
    selectedCountryCode(){ return this.selected.tab=='uk' ? 'GB' : 'US' },
  },
  watch: {
    "selected.tab": {
      immediate: true,
      handler(tab){
        this.response.numbers=[]
        this.response.regions=[]
        this.response.groups=[]
        this.response.cities=[]
        this.selected.region=''
        this.selected.group=''
        this.selected.city=''
        this.forms.assign.number=''
        this.api.numbers.error_message=''
        this.api.numbers.error={}
        if(tab=='usa'){
          this.getregions();
        } else if(tab=='uk'){
          this.fetchCities();
        }
      }
    },
    "selected.region":{
      handler(region){
        if(region){
          this.response.groups=[]
          this.response.numbers=[]
          this.selected.group=''
          this.forms.assign.number=''
          this.api.numbers.error_message=''
          this.api.numbers.error={}
          this.getregiongroups();
        }
      },
    },
    "selected.group":{
      handler(group){
        if(group){
          this.response.numbers=[]
          this.forms.assign.number=''
          this.fetchNumbers();
        }
      },
    },
    "selected.city":{
      handler(city){
        if(city){
          this.response.numbers=[]
          this.forms.assign.number=''
          this.fetchNumbers();
        }
      },
    },
  },
  validations: {
    forms: {
      assign: {
        number: {
          required
        },
      },
    },
  },
  filters: {
    number_formater(number, country=''){
      const phoneNumber = getValidPhoneNumberObject(number, country)
      if (phoneNumber) return phoneNumber.format("NATIONAL")
      else return number;
    },
  },
  methods: {
    async getregiongroups(){
      if(this.api.groups.send) return;
      try {
        this.api.groups.send=true
        this.api.groups.error_message=''
        const { data: { data: { regions: groups } } } = await API.endpoints.auth.fetchregiongroups({
          region: this.selected.region,
        })
        this.response.groups=groups
        this.selected.group=groups?.[0]?.group_id ?? ''
      } catch (ex) {
        this.api.groups.error_message = ex.own_message || ''
      } finally {
        this.api.groups.send=false
      }
    },
    async getregions(){
      if(this.api.regions.send) return;
      try {
        this.api.regions.error_message=''
        this.api.regions.send=true
        const { data: { data: { regions } } } = await API.endpoints.auth.fetchregions()
        this.response.regions=regions
        this.selected.region=regions?.[0]?.code ?? ''
      } catch (ex) {
        this.api.regions.error_message=ex.own_message
      } finally {
        this.api.regions.send=false
      }
    },
    async fetchNumbers(){
      this.api.numbers.source?.cancel?.("Requesting Again");
      this.api.numbers.source = require("axios").default.CancelToken.source()
      // if(this.api.numbers.send) return;
      try {
        this.api.numbers.send=true
        this.api.numbers.error_message=''
        this.pagination.numbers.current_page=1
        const { data: { data: { numbers } } } = await API.endpoints.auth.fetchnumbers({
          type: this.selected.tab,
          region: (this.selected.tab=='uk' ? this.selected.city : this.selected.region) || '',
          group_id: (this.selected.tab=='uk' ? '' : this.selected.group) || '',
        },this.api.numbers.source.token)
        if(_.isEmpty(numbers)){
          throw new Error('No Number Available')
        } else {
          this.response.numbers=numbers
          this.forms.assign.number=numbers?.[0]?.number ?? ''
        }
      } catch (ex) {
        if(!ex.cancel) this.api.numbers.error_message=ex.own_message || ex.message
      } finally {
        this.api.numbers.send=false
      }
    },
    async assign(){
      try {
        this.forms.assign.submitted=true
        this.api.assign.error={}
        this.$v.forms.assign.$touch()
        if(this.api.assign.send || this.$v.forms.assign.$invalid) return;
        const number = this.response.numbers.find(item=>item.number==this.forms.assign.number)
        this.$emit('complete',{
          number: number.number,
          number_type: this.selected.tab,
          city: this.selected.tab=='uk' ? this.selected.city : '',
          sku: number?.sku || '',
          did_group_id: this.selected.tab=='uk' ? '' : this.selected.group,
        })
      } catch(ex) {
        if(ex?.includes('OWN:')){
          this.api.assign.error_message=ex.replace('OWN:','')
        }
      } finally {
        this.forms.assign.submitted=false
      }
    },
    async fetchCities(){
      if(this.api.cities.send) return;
      try {
        this.api.cities.send=true
        this.api.cities.error_message=''
        const { data: { data } } = await API.endpoints.auth.fetchcities({
          id: 44,
        })
        this.response.cities = data
        this.selected.city = 20
      } catch (ex) {
        this.api.cities.error_message=ex.own_message
      } finally {
        this.api.cities.send=false
      }
    },
    onWindowResize(){
      if(window.outerWidth <= 525) {
        this.pagination.numbers.per_page = 4
      }
    },
  },
  mounted(){
    window.addEventListener('resize',this.onWindowResize)
    this.onWindowResize()
  },
  beforeDestroy(){
    window.removeEventListener('resize',this.onWindowResize)
  },
}
</script>

<style>

</style>