<template>
  <modal class="numberRecaptchaModal"
    :name="modalName" 
    @opened="onOpened()" 
    @closed="onClosed()"
  >
    <div>
      <div class="dialer-edit-header align-items-center w-100">
        <div class="customWidth-parent">
          <h2 class="dialer-edit-title newer mb-0">Verify</h2>
        </div>
        <a class="newCloseButton" @click="$modal.hide(modalName)">
          <app-icon icon="squared-close-icon" height="38px" width="38px" />
          <span class="newCloseButton-textPart">Close</span>
        </a>
      </div>
      <div :id="`${_uid}-recaptcha`"></div>
    </div>
  </modal>
</template>

<script>
import { FIREBASE } from '@/firebase'
export default {
  name: 'RecaptchaResolver',
  props: {
    modalName: {
      type: String,
      default: 'RecaptchaResolver',
    },
  },
  data() {
    return {
      resolved: false,
      recaptcha: null,
      resolve: null,
      reject: null,
    }
  },
  methods: {
    onOpened(){
      let vm = this
      const recaptcha = FIREBASE.recaptchaRender(`${this._uid}-recaptcha`,()=>{
        vm.resolved=true
        vm.resolve(recaptcha)
      })
    },
    onClosed(){
      if(!this.resolved) this.reject(new Error('resolve Recaptcha'))
      this.resolved = false
      this.recaptcha = null
      this.resolve = null
      this.reject = null
    },
    getResolver(){
      let vm = this
      return {
        resolve(){
          vm.$modal.show(vm.modalName)
          return new Promise((resolve,reject)=>{
            vm.resolve=resolve
            vm.reject=reject
          })
        },
        complete(){
          vm.$modal.hide(vm.modalName)
        }
      }
    }
  },
}
</script>

<style>

</style>



<style lang="scss">

</style>